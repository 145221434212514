import Vue from 'vue'
/**
 *
 * middleware to populate store before navigation
 * same purpose as the beforeRouteUpdate hooks
 */
export default async function ({store, params, route, redirect}) {
  store.dispatch('updateCart')
  if(!route.path.endsWith('/') && !route.path.endsWith('.xml')){
    redirect(301, route.path+'/')
  }
}
