import Cookies from 'js-cookie'
import { getSlug } from '@/helpers/main'
import { isFilterMatched } from '@/helpers/route'
import TourListController from '~/api/TourListController'

var controller = new TourListController()
let anchorCartCheckTimer = null

const TourList = {
  state: {
    tags: [],
    cityTours: [],
    microData: {},
    cityData: {},
    filters: [],
    tour: {},
    initValues: {},
    compareTag: {},
    compareDetail: {},
    guideProfile: {},
    breadCrumbs: {},
    tourFilter: [],
    anchorCarts: 0,
    unavailabile: false,
    tourMeetingPoint: {}
  },
  actions: {
    async getTourMeetingPoint ({ commit }, payload) {
      const data = await controller.getTourMeetingPoint(payload.tour, this.$axios)
      const { tourMeetingPoint } = data
      commit('setTourMeetingPoint', { tourMeetingPoint })

      return tourMeetingPoint
    },
    // async getTourUnavail (context, payload) {
    //   const data = await controller.getTourUnavailability(payload.tourUrl, payload.direct)
    //   context.commit('setUnavailabile', data)
    //   return data
    // },
    getListContent: async (context, payload) => {
      try {
        const data = await controller.getListContent(payload.city)
        context.commit('setCityTours', data.tours)
        context.commit('setCityData', data.cityData)
        // context.commit('setCityFilters', data.filters)
      } catch (err) {
        console.error(`get city data err  -- ${payload.city}`, err)
        throw({statusCode: 404, message: 'City not found', err})
      }
    },
    getCityToursPrice: async (context, payload) => {
      const data = await controller.getCityToursPrice(payload)
      context.commit('updateCityToursPrice', data)
    },
    async getToursUnavailabilities (context, payload) {
      const data = await controller.getToursUnavailabilities(payload.city)
      context.commit('updateUnavailabilities', data)
    },
    getBreadCrumbs: (context, payload) => {
      const data = controller.buildBreadCrumbs(payload)
      context.commit('setBreadCrumbs', data)
    },
    async getTour (context, payload) {
      try {
        if (payload?.city?.includes("__webpack_hmr")) {
          return
        }
        const data = await controller.getTour(this.$sentry, payload.city, payload.tour)
        context.commit('setTour', data)
        return data?.tour
      } catch(err) {
        console.error(`getTour req err: city: ${payload.city} tour: ${payload.tour}`, err)
        throw({statusCode: 404, message: 'Tour not found'})
      }
    },
    setTourFilters: (context, payload) => {
      context.commit('setTourFilters', payload)
    },
    updateCart ({ commit, dispatch }) {
      const anchorId = Cookies.get('_anchorcid')
      const itemObjStr = Cookies.get('_anchorcidmeta')
      // console.log('update cart...')
      if (itemObjStr && anchorId) {
        const itemObj = JSON.parse(itemObjStr)
        commit('setAnchorCart', itemObj.totalItems)
        if (!anchorCartCheckTimer) {
          anchorCartCheckTimer = setInterval(() => dispatch('updateCart'), 5000)
        }
      } else {
        clearInterval(anchorCartCheckTimer)
        anchorCartCheckTimer = null
        commit('setAnchorCart', 0)
      }
    }
  },
  mutations: {
    updatePriceList: (state, toursPriceMap) => {
      if (!state.city) { return }
      if (!state.city.tourListData) { return }
      if (!state.city.tourListData.tours) { return }
      if (!state.city.tourListData.tours.length) { return }

      const tours = state.city.tourListData.tours.map((tour) => {
        const slug = getSlug(tour)
        const priceData = toursPriceMap[slug]

        return {
          ...tour,
          tour: {
            ...tour.tour,
            priceMap: priceData?.priceMap || null
          }
        }
      })

      state.city = {
        ...state.city,
        tourListData: {
          ...state.city.tourListData,
          tours
        }
      }
    },
    updateTourPrice: (state, tourPriceMap) => {
      const tour = state.tour
      const slug = getSlug(tour)
      const newPrice = tourPriceMap[slug]
      if (newPrice) {
        tour.priceMap = newPrice.priceMap
      }

      const toursBookedTogetherCart = (tour.toursBookedTogetherCart ? tour.toursBookedTogetherCart : []).map(tour => {
        const slug = getSlug(tour)
        return {
          ...tour,
          priceMap: tourPriceMap[slug] ? tourPriceMap[slug].priceMap : null
        }
      })

      const similarTours = (tour.similarTours ? tour.similarTours : []).map(tour => {
        const slug = getSlug(tour)
        return {
          ...tour,
          priceMap: tourPriceMap[slug] ? tourPriceMap[slug].priceMap : null
        }
      })

      state.tour = { ...tour, toursBookedTogetherCart, similarTours }
    },
    setUnavailabile: (state, data) => {
      state.unavailabile = data
    },
    setTourFilters: (state, filters) => {
      state.tourFilter = filters
    },
    setInitialFilter: (state, qry) => {
      const { category } = qry
      let filters = qry.filters ?? []
      if (typeof filters === 'string') {
        filters = [filters]
      }
      if (!category) {
        state.tourFilter = filters
        return
      }
      const matched = state.filters?.find(filter => isFilterMatched(filter, category))
      if (matched) {
        state.tourFilter = [matched]
      } else {
        state.tourFilter = filters
      }
    },
    setTags: (state, data) => {
      state.tags = data
    },
    setCityTours: (state, data) => {
      state.cityTours = data
    },
    setMicroData: (state, data) => {
      state.microData = data
    },
    setCityData: (state, data) => {
      state.cityData = data
    },
    setCityFilters: (state, data) => {
      state.filters = data
    },
    setBreadCrumbs: (state, data) => {
      state.breadCrumbs = data
    },
    setTour: (state, data) => {
      state.tour = data.tour
      state.initValues = {
        priceMap: data.tour.priceMap,
      }
    },
    setTourMeetingPoint: (state, data) => {
      state.tourMeetingPoint = data.tourMeetingPoint
    },
    setCompareTag: (state, data) => {
      state.compareTag = data
    },
    setAnchorCart: (state, data) => {
      state.anchorCarts = data
    },
    updateUnavailabilities: (state, unavailabilities) => {
      // console.log('unavailabilities: ', unavailabilities)
      if (!unavailabilities || unavailabilities.length <= 0) {
        return
      }
      console.log('unavailabilities -> ', unavailabilities)
      const tags = state.tags
      if (tags && tags.length > 0) {
        for (const tag of tags) {
          // console.log('tag -> ', tag.tours)
          for (const tour of tag.tours) {
            if (unavailabilities.includes(tour.url)) {
              tour.flag = 'Currently Unavailable'
              tour.unavailabile = true
            }
          }
        }
      }

      // for new city's tour list
      const cityTours = state.cityTours
      if (cityTours?.length > 0) {
        for (const cityTour of cityTours) {
          console.log('cityTour -> ', cityTour)
          if (unavailabilities.includes(cityTour.url)) {
            cityTour.flag = 'Currently Unavailable'
            cityTour.unavailabile = true
          }
        }
      }
    },
    updateCityToursPrice: (state, priceMaps) => {
      state.cityTours = (state.cityTours || []).map(tour => {
        const slug = getSlug(tour)
        return {
          ...tour,
          priceMap: priceMaps[slug] ? priceMaps[slug].priceMap : null
        }
      })
    },
    updateCityReviews: (state, reviewStatMap) => {
      // console.log('review to update => ', reviewStatMap)
      const tags = state.tags
      for (const tag of tags) {
        for (const tour of tag.tours) {
          // use bookingTypeId as uniqueKey for review
          const { bookingTypeId } = tour
          const reviewStat = reviewStatMap[bookingTypeId.toString()]
          if (reviewStat) {
            tour.reviewStatus = {
              feedbackAverage: reviewStat.average,
              feedbackCount: reviewStat.totalReviews,
            }
          }
        }
      }
    },
    updateTourReviewStats: (state, data) => {
      const { tourReviewStat, similarToursReviewStat} = data
      const tour = state.tour
      // console.log('tourReviewStat => ', tourReviewStat)
      // console.log('similarToursReviewStat => ', similarToursReviewStat)
      if (tourReviewStat) {
        tour.reviewsAverage = tourReviewStat.feedbackAverage
        tour.reviewsCount = tourReviewStat.feedbackCount
        tour.starsGroup = tourReviewStat.starsGroup
      }
      // similar tours
      const similarTours = tour.similarTours
      for (const similarTour of similarTours) {
        const { bookingTypeId } = similarTour
        const reviewStat = similarToursReviewStat[bookingTypeId.toString()]
        if (reviewStat) {
          similarTour.reviewStatus = {
            feedbackAverage: reviewStat.feedbackAverage,
            feedbackCount: reviewStat.feedbackCount,
          }
        }
      }
    }
  },
  getters: {
    tourfilter: state => {
      return state.tourFilter
    },
    tags: state => {
      return state.tags
    },
    filters: state => {
      return state.filters
    },
    microData: state => {
      return state.microData
    },
    cityData: state => {
      return state.cityData
    },
    breadCrumbs: state => {
      return state.breadCrumbs
    },
    tour: state => {
      return state.tour
    },
    compareTag: state => {
      return state.compareTag
    },
    compareDetail: state => {
      return state.compareDetail
    },
    guideProfile: state => {
      return state.guideProfile
    },
    initValues: state => {
      return state.initValues
    },
    anchorCarts: (state) => {
      return state.anchorCarts
    },
    cityTours: (state) => state.cityTours,
    tourMeetingPoint: state => state.tourMeetingPoint,
    unavailabile: state => state.unavailabile
  }
}

export default TourList
