import ReviewController from '~/api/ReviewController'


var controller = new ReviewController()

const Review = {
  state: {
    reviews: [],
    reviewStatus: {}
  },
  actions: {
    async getTourReviews (context, payload) {
      // console.log('payload =>', payload)
      const { slug } = payload
      if (!slug) {
        context.commit('setReviews', [])
        return []
      }
      try {
        const reviews = await controller.getTourReviews(slug, payload.offset, payload.limit)
        context.commit('setReviews', reviews)
        return reviews
      } catch(err) {
        console.error(`getTourReviews error, slug: ${slug}`, err)
        context.commit('setReviews', [])
        return []
      }
    },
    async getTourPageReviewStat (context, payload) {
      const { slug } = payload
      if (!slug) {
        return {}
      }
      const [tourReviewStat, similarToursReviewStat ] = await Promise.all([controller.getTourReviewStat(slug), controller.getSimilarToursStars(slug)])
      context.commit('updateTourReviewStats', {
        tourReviewStat,
        similarToursReviewStat
      })
    },
    async getCityPageReviewStat (context, payload) {
      const { city } = payload
      const reviewStats = await controller.getCityReviewSummary(city)
      context.commit('updateCityReviews', reviewStats)
    },
    async getHomepageReviewStat (context) {
      const reviewStats = await controller.getHomepageReviewState()
      context.commit('updateHomepageReviews', reviewStats)
    }
  },
  mutations: {
    setReviews: (state, data) => {
      state.reviews = data
    },
    setReviewStatus: (state, data) => {
      state.reviewStatus = data
    }
  },
  getters: {
    reviews: state => {
      return state.reviews
    },
    reviewStatus: state => {
      return state.reviewStatus
    }
  }
}

export default Review
