import StaticPagesController from '~/api/StaticPagesController'

const controller = new StaticPagesController()

const StaticPages = {
  state: {
    faq: null,
    about: null,
    terms: null,
    privacy: null,
    californiaprivacy: null,
    contact: null,
    cancellation: null,
    'travel-agents': null,
    'cookies-policy': null,
    ribbonBanner: null,
    signupForm: null
  },
  actions: {
    getPage: (context, payload) => {
      return new Promise((resolve, reject) => {
        controller.getStaticPage(payload.path).then(data => {
          context.commit('setPage', data)
          resolve()
        }, error => reject(error))
      })
    },
    getSalesPage: async (context, payload) => {
      return await controller.getSalesPage(payload.path)
    },
    getPopupSetting: async (context) => {
      const signupForm = await controller.getPopup()
      context.commit('setSignupForm', signupForm)
    },
    getSalesBanner: async (context) => {
      const bannerInfo = await controller.getSalesBanner()
      context.commit('setRibbonBanner', bannerInfo)
    }
  },
  mutations: {
    setPage: (state, data) => {
      // dynamic assignment using state[key] and not state.key...
      state[data.path] = data.result
    },
    setSignupForm: (state, data) => {
      state.signupForm = data
    },
    setRibbonBanner: (state, data) => {
      state.ribbonBanner = data
    }
  },
  getters: {
    faqPage: state => state.faq,
    aboutPage: state => state.about,
    contactPage: state => state.contact,
    termsCondition: state => state.terms,
    privacyPolicy: state => state.privacy,
    californiaprivacy: state => state.californiaprivacy,
    cancelPolicy: state => state.cancellation,
    travelAgents: state => state['travel-agents'],
    cookiesPolicy: state => state['cookies-policy'],
    ribbonBanner: state => state.ribbonBanner,
    signupForm: state => state.signupForm
  }
}

export default StaticPages
