/* eslint-disable comma-spacing */
/* eslint-disable block-spacing */
/* eslint-disable space-infix-ops */
/* eslint-disable semi */
/* eslint-disable no-useless-escape */
export const IsMobile = {
  methods: {
    checkBrowser () {
      // eslint-disable-next-line no-undef
      const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification))
      // eslint-disable-next-line spaced-comment
      const isInterEx = /*@cc_on!@*/false || !!document.documentMode
      const iPhoneiPad = window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i)
      if (isSafari) {
        return true
      } else if (iPhoneiPad) {
        return true
      } else if (isInterEx) {
        return true
      } else {
        return false
      }
    },
    CheckMobile () {
      if (window.innerWidth <= 800) {
        return true;
      } else {
        return false;
      }
    },
    setAutoWidth (url) {
      if (this.checkBrowser()) {
        return url + '?w=' + window.innerWidth
      } else {
        return url + '?w=' + window.innerWidth + '&fm=webp'
      }
    },
    setFixedWidth (url, width) {
      if (this.checkBrowser()) {
        return url + '?w=' + width
      } else {
        return url + '?w=' + width + '&fm=webp'
      }
    },
    setWidthDivision (url, dividend) {
      const width = window.innerWidth / dividend
      if (this.checkBrowser()) {
        return url + '?w=' + width
      } else {
        return url + '?w=' + width + '&fm=webp'
      }
    },
    getContentfulImgURL ({ url, width, height, fit, focus, format, quality, bgColor }) {
      const formatValue = format || 'webp'
      let imgUrl = `https:${url}?fm=${formatValue}`
      if (width) {
        imgUrl = imgUrl + `&w=${width}`
      }
      if (height) {
        imgUrl = imgUrl + `&h=${height}`
      }
      if (quality) {
        imgUrl = imgUrl + `&q=${quality}`
      }
      if (width || height) {
        // only when width and height are set, fit, focus has effects
        const fitValue = fit || 'fill'
        const focusValue = focus || 'center'
        imgUrl = imgUrl + `&fit=${fitValue}&f=${focusValue}`
      }
      if (fit === 'pad' && bgColor) {
        imgUrl = imgUrl + `&bg=${bgColor}`
      }
      return imgUrl
    },
    getPlaceholderURL (url) {
      let curUrl = url.replace('https:', '')
      curUrl = curUrl.replace('http:', '')
      if (curUrl.includes('?')) {
        const partials = curUrl.split('?')
        return `https:${partials[0]}?w=100&h=100&fm=webp&fit=fill&q=20`
      }
      return `https:${curUrl}?w=100&h=100&fm=webp&fit=fill&q=20`
    }
  }
}
