import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { OrderStatus } from '@/helpers/ventrata'

const cartKey = 'ventrata_cart'

// only keep the key fields
const minimizeOrder = (order) => {
  if (!order) {
    return null
  }

  return {
    id: order.id,
    status: order.status,
    bookings: order.bookings,
    utcExpiresAt: order.utcExpiresAt
  }
}
const Ventrata = {
  state: {
    cart: {},
    order: {},
    initAvailabilities: null,
    paymentOrder: null,
    fingerPrint: null
  },
  actions: {
    async lookup (context, payload) {
      return await this.$ventrataController.lookup(this.$axios, payload)
    },
    async createOrder (context, payload) {
      return await this.$ventrataController.createOrder(this.$axios, payload)
    },
    async getOrder (context, orderId) {
      const order = await this.$ventrataController.retrieveOrder(this.$axios, orderId)
      context.commit('setOrder', order)
      return order
    },
    async confirmOrder (context, payload) {
      return await this.$ventrataController.confirmOrder(this.$axios, payload)
    },
    async updateOrder (context, payload) {
      const { orderId, updateInfo } = payload
      return await this.$ventrataController.patchOrder(this.$axios, orderId, updateInfo)
    },
    async updateOrderContact (context, payload) {
      const { orderId, firstName, lastName, emailAddress, phoneNumber, country, notes = {} } = payload
      const curOrder = await this.$ventrataController.retrieveOrder(this.$axios, orderId)
      const bookings = curOrder.bookings.map((booking) => {
        const unitItems = booking.unitItems.map(item => ({ id: item.id, unitId: item.unitId }))
        return {
          packageBookings: booking.packageBookings,
          uuid: booking.uuid,
          unitItems
        }
      })

      let notesString = ''
      if (notes.email) {
        notesString += 'email'
        if (notes.sms) { notesString += ',sms' }
      } else if (notes.sms) { notesString += 'sms' }
      // process update info
      const updateInfo = {
        currency: curOrder.pricing.currency,
        contact: {
          firstName,
          lastName,
          emailAddress,
          phoneNumber,
          locales: ['en', 'en-US', 'en'],
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country,
          notes: notesString,
          allowMarketing: false,
          taxId: ''
        },
        bookings
      }
      return await this.$ventrataController.patchOrder(this.$axios, orderId, updateInfo)
    },
    async cancelOrder (context, orderId, isConfirmed) {
      return await this.$ventrataController.cancelOrder(this.$axios, orderId, isConfirmed)
    },

    async listBookings (context, supplierReference) {
      return await this.$ventrataController.listBookings(this.$axios, supplierReference)
    },

    async updateBooking (context, payload) {
      return await this.$ventrataController.updateBooking(this.$axios, payload)
    },
    async reserveBooking (context, payload) {
      return await this.$ventrataController.reserveBooking(this.$axios, payload)
    },
    async cancelBooking (context, bookingUUId) {
      return await this.$ventrataController.cancelBooking(this.$axios, bookingUUId, false)
    },
    async cancelConfirmedBooking (context, bookingUUId) {
      return await this.$ventrataController.cancelBooking(this.$axios, bookingUUId, true)
    },
    async getCheckoutConfig (context) {
      return await this.$ventrataController.getCheckoutConfig(this.$axios)
    },
    // !Deprecated
    async getCalendar (context, payload) {
      return await this.$ventrataController.getProductCalendar(this.$axios, payload)
    },
    async getAvailability (context, payload) {
      return await this.$ventrataController.getAvailability(this.$axios, payload)
    },
    async initCart ({ dispatch, commit }) {
      const cartStr = localStorage.getItem(cartKey)
      if (cartStr) {
        const itemObj = JSON.parse(cartStr)
        if (itemObj?.id) {
          commit('setCart', itemObj)
          await dispatch('loadCart', itemObj)
        } else {
          commit('setCart', null)
          localStorage.removeItem(cartKey)
        }
      } else {
        commit('setCart', null)
      }
    },
    async loadCart ({ commit }, order) {
      const curOrder = await this.$ventrataController.retrieveOrder(this.$axios, order.id)
      if (curOrder && curOrder.status === OrderStatus.Pending) {
        commit('setCart', order)
      } else {
        localStorage.removeItem(cartKey)
      }
    },
    async refreshCart ({ commit }, orderId) {
      if (!orderId) {
        return
      }
      const curOrder = await this.$ventrataController.retrieveOrder(this.$axios, orderId)
      if (!curOrder.id) {
        // get order error
        commit('setCart', null)
        return
      }
      if (curOrder.status === OrderStatus.Pending) {
        // update current order
        commit('setCart', curOrder)
      } else {
        commit('setCart', null)
      }
      return curOrder
    },
    async updateConfirmedOrder ({ commit }, orderId) {
      if (!orderId) {
        return
      }
      const curOrder = await this.$ventrataController.retrieveOrder(this.$axios, orderId)
      if (!curOrder.id) {
        // get order error
        localStorage.removeItem(cartKey)
        commit('setCart', null)
        return
      }
      commit('setCart', curOrder)
      return curOrder
    },
    updateCart ({ commit, dispatch }, payload) {
      commit('setCart', payload)
      localStorage.setItem(cartKey, JSON.stringify(payload))
    },
    clearCart ({ commit }) {
      commit('setCart', null)
      localStorage.removeItem(cartKey)
    },
    removeBookingFromCart ({ commit, state }, bookingUUID) {
      if (!state.cart) {
        return
      }
      const remainingBookings = state.cart.bookings.filter(booking => booking.uuid !== bookingUUID)

      const newCart = {
        ...state.cart,
        bookings: remainingBookings
      }
      commit('setCart', newCart)
    },
    async loadFingerPrint (context, publicApiKey) {
      // eslint-disable-next-line import/no-named-as-default-member
      const res = await FingerprintJS.load({
        apiKey: publicApiKey,
        endpoint: 'https://fp.ventrata.com',
        scriptUrlPattern: `https://fp.ventrata.com/web/v3/${publicApiKey}/loader_v3.11.5.js`
      })
      context.commit('setFingerPrint', res)
      return res
    },
    async triggerFingerPrint (context, linkedId) {
      if (context.state.fingerPrint) {
        context.state.fingerPrint.get({ linkedId })
      } else {
        // re-load fingerPrint
        const checkoutConfig = await context.dispatch('getCheckoutConfig')
        const publicApiKey = checkoutConfig.fingerprintPublicKey

        const fingerPrint = await context.dispatch('loadFingerPrint', publicApiKey)
        fingerPrint.get({ linkedId })
      }
    },
    async sendSlackMsg (context, msg) {
      return await this.$ventrataController.sendErrMsgToSlack(this.$axios, msg)
    }
  },
  mutations: {
    setInitAvailabilities: (state, availabilities) => {
      state.initAvailabilities = availabilities
    },
    setOrder: (state, data) => {
      state.order = data
    },
    setPaymentOrder: (state, data) => {
      state.paymentOrder = data
    },
    setCart: (state, data) => {
      if (!data) {
        state.cart = null
        localStorage.removeItem(cartKey)
      } else {
        state.cart = minimizeOrder(data)
        localStorage.setItem(cartKey, JSON.stringify(state.cart))
      }
    },
    addTourInfoToCart (state, payload) {
      const { bookingId, tourInfo, order } = payload
      const curOrder = minimizeOrder(order) ?? state.cart
      // add tourInfo to current booking
      const curBooking = curOrder.bookings.find(b => b.id === bookingId)
      if (curBooking) {
        curBooking.tourInfo = tourInfo
      }

      // add back existing bookings' tourInfo if there are other bookings
      const savedBookings = state.cart.bookings
      // console.log('curOrder.bookings :>> ', curOrder.bookings)
      const existingBookings = curOrder.bookings.filter(b => b.id !== bookingId)
      // console.log('existingBookings :>> ', existingBookings)
      if (existingBookings?.length > 0) {
        for (const existingBooking of existingBookings) {
          const existBkId = existingBooking.id
          const savedBooking = savedBookings.find(b => b.id === existBkId)
          // console.log('savedBooking tourInfo:>> ', savedBooking?.tourInfo)
          if (savedBooking) {
            existingBooking.tourInfo = savedBooking.tourInfo
          }
        }
      }
      localStorage.setItem(cartKey, JSON.stringify(curOrder))
      state.cart = curOrder
    },
    setFingerPrint: (state, data) => {
      state.fingerPrint = data
    }
  },
  getters: {
    initAvailabilities: state => state.initAvailabilities,
    cart: state => state.cart,
    order: state => state.order,
    paymentOrder: state => state.paymentOrder,
    fingerPrint: state => state.fingerPrint,
    cartItems: (state) => {
      const cart = state.cart
      return cart?.bookings?.length ?? 0
    }
  }
}


export default Ventrata
