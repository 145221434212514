import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      currencies: [
        { name: 'EUR', symbol: '€' },
        { name: 'USD', symbol: '$' },
        { name: 'GBP', symbol: '£' },
        { name: 'CAD', symbol: '$' },
        { name: 'AUD', symbol: '$' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'currency'
    ])
  },
  methods: {
    getCurrencyLabel () {
      if (this.currency === 'USD' || this.currency === 'CAD' || this.currency === 'AUD') {
        return '$ ' + this.currency
      } else if (this.currency === 'GBP') {
        return '£ ' + this.currency
      } else if (this.currency === 'EUR') {
        return '€ ' + this.currency
      }
    },
    isConfirmationPage () {
      if (this.$route.path === '/confirm/') {
        return true
      } else {
        return false
      }
    },
    currentCurrency () {
      return this.currency
    },
    changeCurrency (val) {
      this.$store.commit('setCurrency', val)
    }
  }
}
