import createPersistedState from 'vuex-persistedstate'

export default ({store}) => {
  window.onNuxtReady(() => {
    createPersistedState({
      key: 'takewalks_state',
      paths: ['Common.currency'],
      storage: window.localStorage
    })(store)
  })
}