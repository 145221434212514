const getDisplayPrice = (price) => {
  const p = Number(price)
  return Math.round(p)
}
export default {
  methods: {
    getPrice (tour, currency) {
      if (!currency) {
        currency = this.$store.state.Common.currency
      }
      const priceMap = tour.priceMap
      if (!priceMap || !priceMap.anchorPrice) {
        return '$0'
      }
      const currencyData = priceMap.currencies.find(c => c.id === currency)
      const roundUpPrice = getDisplayPrice(priceMap.anchorPrice * currencyData.rate)

      if (currencyData) {
        switch (currency) {
          case 'USD':
            return '$' + roundUpPrice
          case 'EUR':
            return '€' + roundUpPrice
          case 'GBP':
            return '£' + roundUpPrice
          case 'CAD':
            return '$' + roundUpPrice
          case 'AUD':
            return '$' + roundUpPrice
          default:
            return '$' + roundUpPrice
        }
      }
    },
    getDiscountPrice (tour, currency) {
      if (!currency) {
        currency = this.$store.state.Common.currency
      }
      const priceMap = tour.priceMap
      if (!priceMap || !priceMap.discountedPrice) {
        return '$0'
      }
      const currencyData = priceMap.currencies.find(c => c.id === currency)
      const roundUpPrice = getDisplayPrice(priceMap.discountedPrice * currencyData.rate)

      if (currencyData) {
        switch (currency) {
          case 'USD':
            return '$' + roundUpPrice
          case 'EUR':
            return '€' + roundUpPrice
          case 'GBP':
            return '£' + roundUpPrice
          case 'CAD':
            return '$' + roundUpPrice
          case 'AUD':
            return '$' + roundUpPrice
          default:
            return '$' + roundUpPrice
        }
      }
    },
    getPrefixSymbol () {
      const curCurrency = this.$store.state.Common.currency
      if (curCurrency === 'AUD') {
        return 'AU'
      } else if (curCurrency === 'CAD') {
        return 'CA'
      }
      return null
    },
    getAnchorPriceNum (tour, currency = 'USD') {
      const priceMap = tour.priceMap
      if (!priceMap || !priceMap.anchorPrice) {
        return 0
      }
      const currencyData = priceMap.currencies.find(c => c.id === currency)
      const price = Number(priceMap.anchorPrice * currencyData.rate).toFixed(2)
      return price
    },
    getPriceOld (value) {
      /** value.price.data -> listing page,  value.price -> detail page*/
      const data = value.price.data ? value.price.data : value.price;
      switch (this.$store.state.Common.currency) {
        case 'USD':
          return '$' + data.adults_price.USD
        case 'EUR':
          return '€' + data.adults_price.EUR
        case 'GBP':
          return '£' + data.adults_price.GBP
        case 'CAD':
          return '$' + data.adults_price.CAD
        case 'AUD':
          return '$' + data.adults_price.AUD
        default:
          return '$' + data.adults_price.USD
      }
    },
    /**
    * WLK-3270-promo
    * @param {Array} promos - Array containing all current promoted tours *
    * @param {Int} eventId - ID of event in questions which is checked against promos var *
    */
    checkForPromo (promos, eventId) {
      if (promos.includes(eventId)) {
        return true
      } else {
        if (promos[0] === 'all') {
          return true
        } else {
          return false
        }
      }
    },

  }
}
