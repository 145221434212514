import TourListController from '~/api/TourListController'
var controller = new TourListController()

const City = {
  state: {
    city: {},
    loadingAvailability: false,
  },
  actions: {
    getCity: async (context, payload) => {
      const data = await controller.getListContent(payload.city)
      const blog = data.cityData?.blogs ? data.cityData.blogs : []
      const otherLocations = data.cityData?.otherLocations?.locations ? data.cityData.otherLocations.locations : []

      return {
        ...data.cityData,
        blogs: blog.map(blog => ({
          poster: {
            id: blog.imgUrl,
            url: blog.imgUrl,
            alt: blog.description,
          },
          date: blog.date,
          link: blog.pageLink,
          title: blog.description,
        })),
        similarDestinations: {
          otherLocations: otherLocations.map(location => ({
            id: location.id,
            name: location.name,
            pageLink: location.pageLink,
            imgUrl: location.imgUrl,
            imgAlt: location.name,
          }))
        }
      }
    },
  },
  mutations: {
    setLoading: (state, isLoading) => {
      state.loadingAvailability = isLoading
    },
  },
  getters: {
    loadingAvailability: state => state.loadingAvailability
  }
}

export default City