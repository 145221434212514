// plugins/ventrata.client.js
export default function (_ , inject) {
  const loadVentrataWidget = (containerId, token) => {
    const script = document.createElement('script')
    script.src = 'https://checkout.ventrata.com/checkout/widget/js?locale=en&prefix=checkout'
    script.setAttribute('data-layout', 'embed')
    script.setAttribute('data-color', '#F7F7F7')
    script.setAttribute('data-token', token)
    script.setAttribute('data-landing', 'checkin')
    script.setAttribute('data-target', `#${containerId}`)
    script.setAttribute('data-container', `${containerId}`)
    document.head.appendChild(script)
    
    return new Promise((resolve) => {
      script.onload = () => resolve(true)
    })
  }

  inject('ventrata', {
    loadWidget: loadVentrataWidget
  })
}