import Cookies from 'js-cookie'
import * as qs from 'querystring'
import {
  removeTrailingSlash,
  capitalize
} from '@/helpers/route'
import {
  getProducUtag,
  getOrderUtagFormatDataByBooking,
  getOrderUtagFormatData,
  getBookingUtagFormatData,
  getUtagContact
} from '@/helpers/ventrata'

/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
export const gtmDatalayer = {
  methods: {
    gtmPushDatalayer (payload) {
      window.dataLayer.push(payload)
    },
    gtmPageView (pageView) {
      window.dataLayer.push({
        'event': 'customPageView',
        'pageView': pageView
      })
    },
    gtmPushLinkClick (payload) {
      const obj = {
        event: payload.event.trim(),
        click: {
          clickText: capitalize(payload.text),
          clickURL: removeTrailingSlash(payload.url)
        }
      }
      this.gtmPushDatalayer(obj)
    },
    /**
     * add query string for url so Tap Affiliate can track commission accross site (WOI -> TW)
     * @param {string} url
     * @returns
     */
    addQryString(url) {
      const queryObj = this.$route.query
      const qryStr = qs.stringify(queryObj)
      if (qryStr) {
        return `${url}?${qryStr}`
      }
      return url
    },
    getOneTrustSettings () {
      let otEnabled = true
      const otCookieConsent = (Cookies.get('OptanonConsent') || '').split('&').filter(Boolean)
      for (let i = 0; i < otCookieConsent.length; i++) {
        const eachOt = otCookieConsent[i].split('=')
        if (eachOt[0] === 'groups') {
          // console.log('ot 0 => ', eachOt)
          const groupArr = decodeURIComponent(eachOt[1]).split(',')
          for (let j = 0; j < groupArr.length; j++) {
            const eachOtInGroup = groupArr[j].split(':')
            if (eachOtInGroup[0] === 'C0002' && eachOtInGroup[1] === '1') {
              otEnabled = false
            }
          }
        }
      }
      // console.log('ot enabled => ', otEnabled)
      return otEnabled
    },
    /**
    * WLK-2706 - step 31
    * WLK-8570 - https://walks-llc.atlassian.net/browse/WLK-8570
    * @param {Object} tourList - Contains details for current tour in view *
    */
    gtmProductDetailsView (tourList) {
      const products = []
      const priceCurrency = {}
      const tourMetaData = {}
      const obj = {
        event: 'productDetailView',
        priceCurrency,
        tourMetaData,
        ecommerce: {
          detail: {
            actionField: { list: 'Product Pages' },
            products
          }
        }
      }
      tourList.forEach((product, i) => {
        const ventrataProduct = product.ventrataProduct
        priceCurrency.priceEUR = getTourPriceNum(product.priceMap, 'EUR')
        priceCurrency.priceGBP = getTourPriceNum(product.priceMap, 'GBP')
        priceCurrency.priceCAD = getTourPriceNum(product.priceMap, 'CAD')
        priceCurrency.priceAUD = getTourPriceNum(product.priceMap, 'AUD')
        priceCurrency.priceUSD = getTourPriceNum(product.priceMap, 'USD')
        product.gallery.forEach((image, i) => {
          tourMetaData['tourImage' + i] = 'https:' + image.url
        })
        tourMetaData.tourDescription = product.descriptionTitle
        products.push({
          name: product.shortTitle,
          id: product.bookingTypeId,
          ventrata_product_id: ventrataProduct.id,
          ventrata_product_code: ventrataProduct.reference,
          property_id: product.propertyId,
          price: getTourPriceNum(product.priceMap, 'USD'),
          category: product.cityName,
          dimension3: product.cityName,
          dimension4: product.countryName,
          dimension5: product.countryName,
          dimension6: timeStringToFloat(product.duration),
          dimension7: product.reviewStatus?.feedbackAverage || 0,
          dimension8: product.reviewStatus?.feedbackCount || 0,
          dimension9: product.maxGroupSize
        })
      })
      this.gtmPushDatalayer(obj)
    },
    // https://walks-llc.atlassian.net/browse/WLK-8570
    utagProductDetailsView(product) {
      try {        
        const productObj = getProducUtag(product)
  
        const obj = {
          event_name: 'product_view',
          event: 'product_view',
          source_id: 'web',
          ...productObj
        }
  
        console.log('utag.link', obj)
        this.checkUtagReady(() => utag.link(obj))
      } catch (e) {
        console.error('Error in utagProductDetailsView', e)
      }
    },
    utagAddToCart(tourInfo, booking) {
      try {
        const ventrataProduct = tourInfo.ventrataProduct
        const bookingData = getBookingUtagFormatData(booking)
        const obj = {
          event_name: 'add_to_cart',
          event: 'addToCart',
          source_id: 'web',
          ventrata_product_id: ventrataProduct.id,
          ventrata_product_code: ventrataProduct.reference,
          ...bookingData,
        }
        console.log('utag.link', obj)
        this.checkUtagReady(() => utag.link(obj))
      } catch (e) {
        console.error('Error in utagAddToCart', e)
      }
    },
    utagPurchaseOnce(order, cardType) {
      try {
        const contact = order.contact
        const contactInfo = getUtagContact(contact)
        const orderData = getOrderUtagFormatData(order)
        const pricing = order.pricing
        const originalPrice = pricing.original / 100
        const retailPrice = pricing.retail / 100
        const orderDiscount = originalPrice - retailPrice
        const totalPriceStr = originalPrice.toFixed(2)
        const obj = {
          event_name: 'purchase',
          event: 'purchase',
          source_id: 'web',
          order_id: order.supplierReference,
          cart_id: order.supplierReference,
          // order_reference: order.supplierReference,
          order_total: retailPrice.toFixed(2),
          order_subtotal: totalPriceStr,
          order_discount: orderDiscount.toFixed(2),
          order_tax: '0.00',
          currency: pricing.currency,
          order_payment_type: [cardType], // card type
          ...orderData,
          ...contactInfo
        }
        console.log('utag.link', obj)
        this.checkUtagReady(() => utag.link(obj))
      } catch (e) {
        console.error('Error in utagPurchase', e)
      }
    },
    // utagPurchase(order, cardType) {
    //   try {
    //     if (order.bookings?.length <= 0) {
    //       return
    //     }
    //     for (const booking of order.bookings) {
    //       const pricing = booking.pricing
    //       const originalPrice = pricing.original / 100
    //       const retailPrice = pricing.retail / 100
    //       const orderDiscount = originalPrice - retailPrice
    //       const totalPriceStr = originalPrice.toFixed(2)

    //       const bookingsEvent = getOrderUtagFormatDataByBooking(booking)
    //       const purchaseObj = {
    //         event_name: 'purchase',
    //         event: 'purchase',
    //         source_id: 'web',
    //         // order_id: order.id,
    //         cart_id: order.supplierReference,
    //         order_reference: order.supplierReference,
    //         order_total: retailPrice.toFixed(2),
    //         order_subtotal: totalPriceStr,
    //         order_discount: orderDiscount.toFixed(2),
    //         order_tax: '0.00',
    //         currency: pricing.currency,
    //         order_payment_type: [cardType], // card type
    //         ...bookingsEvent
    //       }
    //       console.log('utag.link purchaseObj :>> ', purchaseObj)
    //       this.checkUtagReady(() => utag.link(purchaseObj))
    //     }
    //   } catch (e) {
    //     console.error('Error in utagPurchase', e)
    //   }
    // },
    checkUtagReady(callback, maxAttempts = 10, attempts = 0) {
      const check = () => {
        if (typeof utag !== 'undefined' && typeof utag.link === 'function') {
          callback()
        } else if (attempts < maxAttempts) {
          attempts++
          setTimeout(check, 100)
        } else {
          console.warn('Tealium utag not available after maximum attempts')
        }
      }
      
      check()
    },
    // https://walks-llc.atlassian.net/browse/WLK-8649
    utagBilling (order) {
      // For Step 1
      try {
        const contact = order.contact
        const contactInfo = getUtagContact(contact)
        const orderData = getOrderUtagFormatData(order)
        const obj = {
          event_name: 'billing',
          event: 'billing',
          source_id: 'web',
          ...orderData,
          ...contactInfo,
        }
        console.log('utag link utagBilling =>', obj)
        this.checkUtagReady(() => utag.link(obj))
      } catch (e) {
        console.error('Error in utagBilling', e)
      }
    },
    utagSpecialRequests (order) {
      // For Step 2
      try {
        const orderData = getOrderUtagFormatData(order)
        const contact = order.contact
        const contactInfo = getUtagContact(contact)
        const obj = {
          event_name: 'special_requests',
          event: 'special_requests',
          source_id: 'web',
          ...contactInfo,
          ...orderData
        }
        console.log('utag link utagSpecialRequests =>', obj)
        this.checkUtagReady(() => utag.link(obj))
      } catch (e) {
        console.error('Error in utagSpecialRequests', e)
      }
    },
    utagPayment (order) {
      // For Step - Payment
      try {
        const orderData = getOrderUtagFormatData(order)
        const contact = order.contact
        const contactInfo = getUtagContact(contact)
        const obj = {
          event_name: 'payment',
          event: 'payment',
          source_id: 'web',
          ...contactInfo,
          ...orderData
        }
        console.log('utag link utagPayment =>', obj)
        this.checkUtagReady(() => utag.link(obj))
      } catch (e) {
        console.error('Error in utagPayment', e)
      }
    }
  }
}
