import Vuex from 'vuex'

import Home from './Home'
import Common from './Common'
import Review from './Review'
import TourList from './TourList'
import StaticPages from './StaticPages'

import City from './City'
import Ventrata from './Ventrata'

let store

const createStore = () => {
  return store || (store = new Vuex.Store({
    namespaced: true,
    modules: {
      Home,
      Common,
      Review,
      TourList,
      StaticPages,

      city: { namespaced: true, ...City },
      ventrata: { namespaced: true, ...Ventrata },
    }
  }));
};

export default createStore