import Vue from 'vue'

const Common = {
  state: {
    screenWidth: 360,
    currency: 'USD',
    status: {} ,
    loading: false,
    headerCartVisible: false,
    curSorter: { label: 'Popularity', value: 'popular' },
    ua: { mobile: undefined, desktop: undefined },
    selectedFilters: []
  },
  actions: {
    setSelectedFilters({ commit }, payload) {
      commit('setSelectedFilters', payload)
    }
  },
  mutations: {
    setUA: (state, data) => {
      if (!process.client) {
        state.ua = data
      }
    },
    setScreenWidth: (state, data) => {
      if (data) {
        state.screenWidth = data
      }
    },
    setCurrency: (state, cur) => {
      Vue.localStorage.set('Currency', cur)
      state.currency = cur
    },
    setStatus: (state, status) => {
      state.status = status
    },
    setHeaderCartVisible: (state, visible) => {
      state.headerCartVisible = visible
    },
    setSorter: (state, sorter) => {
      if (!sorter) {
        state.curSorter = { label: 'Popularity', value: 'popular' }
        return
      }
      state.curSorter = sorter
    },
    setSelectedFilters: (state, filters) => {
      state.selectedFilters = filters
    },
    setLoading: (state, isLoading) => {
      state.loading = isLoading
    }
  },
  getters: {
    ua: state => {
      return state.ua
    },
    currency: state => {
      return state.currency
    },
    status: state => {
      return state.status
    },
    menu: state => {
      return state.menu
    },
    headerCartVisible: state => {
      return state.headerCartVisible
    },
    curSorter: state => state.curSorter,
    selectedFilters: state => state.selectedFilters,
    screenWidth: state => state.screenWidth,
    loading: state => state.loading
  }
}

export default Common
